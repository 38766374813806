import { initFragmentOnClient } from 'lib/ssr-client-react'
import fragment from './index'
import { createLogger } from 'common/log'

const logger = createLogger('fragments/inPageSearchFragment/client')

const fragmentName = 'inPageSearchFragment'

// DO NOT CHANGE UNLESS YOU REALLY KNOW WHAT YOU ARE DOING
initFragmentOnClient(fragment.Component, fragmentName)
if (module.hot) {
  module.hot.accept('./index', () => {
    logger.debug(`hot reloading ${fragmentName}`)
    const reload = require('./index').default
    initFragmentOnClient(reload.Component, fragmentName)
  })
}

import React from 'react'

const ChipItem = ({ title, url, isNewTab }) => {
  return (
    <a
      className='in-page-search-chip-item flex justify-center items-center'
      href={url}
      target={isNewTab ? '_blank' : '_self'}
      rel='noreferrer'
    >
      {title}
    </a>
  )
}

export default ChipItem

import { GET_IN_PAGE_SUGGESTIONS_SUCCESS } from './actions'

export const suggestionsReducer = (state, action) => {
  switch (action.type) {
    case GET_IN_PAGE_SUGGESTIONS_SUCCESS:
      return { suggestions: action.suggestions, softContentSuggestions: action.softContentSuggestions }
    default:
      return state
  }
}

import React from 'react'
import SuggestionChips from '../SuggestionChips/SuggestionChips'
import SearchInputContainer from '../../../../common/components/Search/SearchInputContainer/SearchInputContainer'

const View = props => {
  if (!props) return null
  const { menu, title } = props?.inPageSearch ?? {}
  if (!menu && !title) return null

  return (
    <div className='in-page-search-container flex-column items-center'>
      <h2 className='in-page-search-title'>{title}</h2>
      <div id='search-container' className='mxd-container'>
        <SearchInputContainer
          {...{
            ...props,
            isInPage: !!props?.id
          }}
        />
      </div>
      <SuggestionChips menu={menu} />
    </div>
  )
}

export default View

import React, { useReducer } from 'react'
import { getTranslator } from 'client/common/translator'
import View from './components/view/View'
import { ChakraProvider } from '@chakra-ui/react'
import { chemistryTheme } from 'chemistry'
import { suggestionsReducer } from './store/reducer'
import { onSuggestionsChange } from './store/actions'

const Component = props => {
  const [suggestionStore, dispatch] = useReducer(suggestionsReducer, {
    suggestions: props.suggestions,
    softContentSuggestions: props.softContentSuggestions
  })
  const { format, lang, ipAddress } = props
  const t = getTranslator(props.translations)

  return (
    <ChakraProvider resetCSS={false} theme={chemistryTheme}>
      <div id='inPageSearch'>
        <View
          {...{
            ...props,
            t,
            suggestions: suggestionStore.suggestions,
            softContentSuggestions: suggestionStore.softContentSuggestions,
            onSuggestionsChange: onSuggestionsChange({ format, lang, ipAddress, dispatch })
          }}
        />
      </div>
    </ChakraProvider>
  )
}

export default { Component }

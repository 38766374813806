import React from 'react'
import ChipItem from '../ChipItem/ChipItem'

const SuggestionChips = ({ menu }) => {
  const menuItems = menu?.itemsCollection?.items
  return (
    <div className='in-page-search-chips flex justify-center items-center'>
      {menuItems?.map(item => (
        <ChipItem {...item} />
      ))}
    </div>
  )
}

export default SuggestionChips

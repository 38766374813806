import axios from 'axios'
import throttle from 'client/common/throttle'

const USER_INPUT_DELAY = 400

export const GET_IN_PAGE_SUGGESTIONS_SUCCESS = 'GET_IN_PAGE_SUGGESTIONS_SUCCESS'

const getRequestOptions = ipAddress => ({ headers: { ip: ipAddress } })

const fetchSuggestions = ({ host, format, lang, term, ipAddress, dispatch }) => {
  return axios
    .get(`${host}/search-service/rest/v3/${format}/${lang}/suggestions?term=${term}`, getRequestOptions(ipAddress))
    .then(resp => {
      dispatch({
        type: GET_IN_PAGE_SUGGESTIONS_SUCCESS,
        suggestions: resp.data.data,
        softContentSuggestions: resp.data.contentData
      })
      return resp
    })
}

export const removeSpecialChars = term => term.replace(/[^0-9a-zA-Z:,\s]+/g, '')

let lastTerm = ''

export const onSuggestionsChange = ({ format, lang, ipAddress, dispatch }) => {
  return term => {
    const termWithNoSpecialChars = removeSpecialChars(term)
    if (termWithNoSpecialChars === lastTerm) return
    lastTerm = termWithNoSpecialChars
    if (term === '') {
      dispatch({ type: GET_IN_PAGE_SUGGESTIONS_SUCCESS, suggestions: [] })
      return
    }
    const { origin } = new URL(global.document.location.href)
    const { SEARCH_SERVICE_HOST, MODE } = process.env
    const host = MODE === 'test' && SEARCH_SERVICE_HOST ? `http://${SEARCH_SERVICE_HOST}` : origin

    return throttle(
      () =>
        fetchSuggestions({
          host,
          format,
          lang,
          term: termWithNoSpecialChars,
          ipAddress,
          dispatch
        }),
      USER_INPUT_DELAY
    )
  }
}
